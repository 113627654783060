<template>
  <div>
    <p><strong>Usage Pricing</strong> </p>
    <br>
    <div class="result-block-container">

      <div :class="['result-block', executed ? 'show' : '']">
         <div>
          <!-- v-show="this.disableBtn" -->
          <button type="button" class="btn btn-success btn-sm" @click="onStartPricing()" >Start Pricing</button>
          </div>
        <!-- <button type="button" class="btn btn-success btn-sm" v-b-modal.pricinglog-modal>Start Pricing</button> -->
        <br><br>
        <!-- <input type="search" v-model="filter"> -->
        <table class="table table-striped w-auto" id="apirecords">
          <thead >
            <tr>
              <th scope="col" @click="sort('Name')">Pricing stage</th>
              <th scope="col" @click="sort('StartedOn')">Started on</th>
              <th scope="col" @click="sort('Finished')">Finished</th>
              <th scope="col" @click="sort('FinishedOn')">Finished on</th>
              <th scope="col" @click="sort('Message')">Status</th>
              <th scope="col" @click="sort('CallCount')">Call count</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(pricinglog, index) in SortedapiCDRLoads" :key="index">
              <td>{{ pricinglog.Name }}</td>
              <td>{{ pricinglog.StartedOn }}</td>
              <td>{{ pricinglog.Finished }}</td>
              <td>{{ pricinglog.FinishedOn }}</td>
              <td>{{ pricinglog.Message }}</td>
              <td>{{ pricinglog.CallCount }}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <!-- <div>
          <button type="button" class="btn btn-danger btn-sm" @click="onResetLoad()">Reset Pricing</button>
          </div> -->
      </div>
    </div>


  </div>
</template>

<script>
import Alert from '../components/Alert.vue';
import Filter from '../components/Filter.vue';
import CarrierDropDownVue from "../components/CarrierDropDown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
      timer: "",
      selectedCarrier:"",
      addpricinglogForm: {
        Carrier: '' ,
        CusBillingName: '' ,
        FileName: ''

      },
      apipricing: null,
      executed: false,
      message: 'initial alert',
      currentSort:'StartedOn',
      currentSortDir:'asc',
      pageSize:20,
      currentPage:1,
      filter:'',
      apibills: null,
      disableBtn : true
    };
  },

  computed:{
  filteredpricing() {
    console.log('filtered pricinglog');
      return this.apipricing.filter(c => {
        if(this.filter == '') return true;
        return c.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
      })
    },
  SortedapiCDRLoads:function() {
    console.log('sort pricinglog');
    return this.filteredpricing.slice().sort((a,b) => {
      let modifier = 1;
      if(this.currentSortDir === 'desc') modifier = -1;
      if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
      if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
      return 0;
    }).filter((row, index) => {
      let start = (this.currentPage-1)*this.pageSize;
      let end = this.currentPage*this.pageSize;
      if(index >= start && index < end) return true;
    });
  },
  },
  methods: {
    carrierChange: function (e) {
        this.selectedCarrier = e;
    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredpricing.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    sort:function(s) {
    //if s == current sort, reverse
    if(s === this.currentSort) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
    }
    this.currentSort = s;
    },

    // *******************************************
    // List pricing
    // *******************************************
    async callApi(lablex) {

      console.log(lablex)
      console.log('getting the data')
      try {
        const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/pricing`);

        this.apipricing = data;
        console.log('the api data is....')
        console.log(data)
        this.executed = true;
      } catch (e) {
        this.apipricing = `Error: the server responded with '${e.response}'`;
      }
    },
    // Add pricinglog method to post to API
    async addpricinglog() {

    //   let formData = new FormData();
      console.log( 'START PRICING ');
      console.log(`${process.env.VUE_APP_API_BASE_URL}api/v1/pricing`);
    //   formData.append('Carrier', payload.Carrier);
    //   formData.append('BillingName', payload.BillingName);
    //   formData.append('file', payload.FileName);
      try {
        const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/pricing`, null).then(() => {
          this.message = 'pricingl started!';
          this.showMessage = true;
          this.callApi();
          console.log( 'IN PRICING ');

        }).catch((err) => {
          console.log( err);
          if(err.response.status == 409) {
            alert("Duplicated pricinglog. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add pricinglog. status code:  " + err.response.status)
            }
          console.error(err);
          this.callApi();

        });

        // this.apipricing = data;
        // this.executed = true;
        console.log(data)
        //this.callApi()
      } catch (e) {
        this.apipricing = `Error: the server responded with '${e.response}'`;
      }
    },
    initForm() {
      console.log('init form');
        this.addpricinglogForm.Carrier =  '';
        this.addpricinglogForm.BillingName =  '';
        this.addpricinglogForm.FileName =  '';

    },
    // *******************************************
    // Add pricinglog on submit
    // *******************************************
    onStartPricing() {
      console.log('on start pricing');
    //   evt.preventDefault();
    //   this.$refs.addpricinglogModal.hide();
    //   console.log('MaintenaceOnly');

    //   const payload = {
    //     FileName: this.addpricinglogForm.FileName ,
    //     Carrier: this.selectedCarrier,
    //     BillingName: this.addpricinglogForm.Address1,
    //   };

      this.addpricinglog();
      //this.initForm();
    },
    onReset(evt) {
      evt.preventDefault();
      this.$refs.addpricinglogModal.hide();
      this.initForm();
    },
  //
    onResetLoad() {
      console.log('onresetload')
      if(confirm("Are you sure you want to reset all CDR loads?")){
        this.resetLoad();
      }

    },
    // *******************************************
    // List bill logs
    // *******************************************
    async callBillLogApi(lablex) {

      console.log(lablex)
      console.log('***********************getting the data')

      try {
        const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/bills/billlogs`);

        this.apibills = data;
        console.log("in bill log.................");
        console.log(data);
        if (this.apibills.StageNumber > 22){
          this.disableBtn = false
        }
        this.executed = true;
        // stop the autorefresh
        // if (this.apibills.Name==='ORDER GENERATION COMPLETED'){
        //   console.log("inside stop auto refresh")
        //   this.cancelAutoUpdate();
        // }
      } catch (e) {
        this.apibills = `Error: the server responded with '${e.response}'`;
      }
      },
    // reset load
    // *******************************************
    async resetLoad() {

      console.log("in reset load before")
      try {
        const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/pricing/reset`).then(() => {
          this.message = 'account removed!';
          this.showMessage = true;
          console.log("in removeAccount after")
          this.callApi("coming from delete");

        }).catch((err) => {
          console.log("in removeAccount error")
          console.error(err);
          this.callApi();

        });

        // this.apiAccounts = data;
        // this.executed = true;
        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiAccounts = `Error: the server responded with '${e.response}'`;
      }
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },
  created() {
    console.log('created')
    this.callBillLogApi();
    this.callApi();
    this.timer = setInterval(this.callApi, 20000);

  },
  components: {
    alert: Alert,
    listfilter:Filter,
    CarrierDropDownVue: CarrierDropDownVue,
  },

  beforeDestroy() {
    this.cancelAutoUpdate();
  },
};
</script>
