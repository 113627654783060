<template>

    <div>
      <p ><u><strong>Hiya Bundles List</strong></u> </p>
      <br>
      <div class="result-block-container">
        <div :class="['result-block', executed ? 'show' : '']">
          <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.hiyabundle-modal>Insert Hiya Bundle</button>
          <br><br>
          <input type="search" v-model="filter">
          <table class="table table-striped w-auto" id="apirecords">
            <thead>
              <tr>
                <th class="th-lg" scope="col" @click="sort('BundleName')" >Bundle Name</th>
                <th class="th-lg" scope="col" @click="sort('BundleName')" >Bundle Cost</th>
                <th class="th-lg" scope="col" @click="sort('InclusiveQty')">Inclusive Quantity</th>
                <th class="th-lg" scope="col" @click="sort('ExclusiveCharge')">Exclusive Charge</th>
                <th scope="col" @click="sort('ModifiedOn')">Date modified</th>
                <th scope="col" @click="sort('ModifiedBy')">Modified by</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(hiyabundle, index) in SortedapiHiyaBundles" :key="index">
                <td>{{ hiyabundle.BundleName }}</td>
                <td>{{ hiyabundle.BundleCost }}</td>
                <td>{{ hiyabundle.InclusiveQty }}</td>
                <td>{{ hiyabundle.ExclusiveCharge }}</td>
                <td>{{ hiyabundle.ModifiedOn }}</td>
                <td>{{ hiyabundle.ModifiedBy }}</td>
                <td>
                  <div class="btn-group"  role="group">
                    <button
                            type="button"
                            class="btn btn-warning btn-sm"
                            v-b-modal.hiyabundle-update-modal
                            @click="editHiyaBundle(hiyabundle)">
                            {{editButtonText}}
                    </button>
                    <p>  </p>
                    <button v-if="isAdmin" v-on:click="onDeleteHiyaBundle(hiyabundle)"  type="button"
                            class="btn btn-danger btn-sm">
                        Delete
                    </button>
  
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-show="showPaging"><p align="center" >
            <b-pagination
              v-model="currentPage"
              align="center"
              :total-rows="filteredHiyaBundles.length"
              :per-page="pageSize"
              aria-controls="apirecords"
              ></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
            </p></div>
        </div>
      </div>
  

      <!-- ADD modal -->
      <b-modal ref="addHiyaBundleModal"
              id="hiyabundle-modal"
              title="Insert a new hiya bundle"
              hide-footer size="m" >
        <b-form @submit="onSubmit" @reset="onReset" class="w-200">
        <b-form-row>
          <b-col>
              <b-form-group id="form-Bundlename-group"
                      label="Bundle Name"
                      label-for="form-Bundlename-input" label-cols="4" label-cols-sm="4" label-size="sm">
              <b-form-input id="form-Bundlename-input"
                              type="text"   size="sm"
                              v-model="addHiyaBundleForm.BundleName"
                              required
                              placeholder="Enter name" w-20>
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
  
          <b-form-row>
          <b-col>
               <b-form-group id="form-BundleCost-group"
                        label="Bundle Cost"
                        label-for="form-BundleCost-input" label-cols="4" label-cols-lg="4" label-size="sm">
              <b-form-input id="form-BundleCost-input"
                        type="text"   size="sm"
                        v-model="addHiyaBundleForm.BundleCost"
                        required
                        placeholder="Enter bundle cost">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
  
        <b-form-row>
          <b-col>
              <b-form-group id="form-InclusiveQty-group"
                        label="Inclusive Quantity"
                        label-for="form-InclusiveQty-input" label-cols="4" label-cols-lg="4" label-size="sm">
              <b-form-input id="form-InclusiveQty-input"
                        type="text"   size="sm"
                        v-model="addHiyaBundleForm.InclusiveQty"
                        required
                        placeholder="Enter inclusive quantity">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-ExclusiveCharge-group"
                        label="Exclusive Charge"
                        label-for="form-ExclusiveCharge-input" label-cols="4" label-cols-lg="4" label-size="sm">
              <b-form-input id="form-ExclusiveCharge-input"
                        type="text"   size="sm"
                        v-model="addHiyaBundleForm.ExclusiveCharge"
                        required
                        placeholder="Enter exclusive charge">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
         <b-form-row>
            <b-col>
             <b-form-group id="form-Carrier-group"
              label="Carrier"
                        label-for="form-InclusiveText-input" label-cols="4" label-cols-lg="4" label-size="sm" >
              <div >
                  <CarrierDropDownVue @carrier-changed="carrierChange"></CarrierDropDownVue>
          </div>
          </b-form-group>
          </b-col>
        </b-form-row>
          <b-form-row v-show="false">
             <b-col>
              <b-form-group id="form-GroupBundle-group"
                        label="Group Bundle"
                        label-for="form-GroupBundle-input" label-cols="4" label-cols-lg="4" label-size="sm" >
              <div class="dropdown" align="left">
                <b-form-checkbox id="GroupBundle"
                    v-model="addHiyaBundleForm.GroupBundle" name="GroupBundle">
              </b-form-checkbox>
              </div>
              </b-form-group>
          </b-col>
          </b-form-row>
        <b-form-row>
            <b-col>
         <p></p>
          </b-col>
          </b-form-row>
          <div class="container">
          <div class="row">
              <div class="col text-center">
              <b-button-group>
            <b-button type="submit" variant="primary">Submit</b-button>
            <b-button type="reset" variant="danger">Reset</b-button>
          </b-button-group>
              </div>
          </div>
          </div>
        </b-form>
      </b-modal>
  
      <!-- EDIT modal -->
      <b-modal :key="editcomponentKey" ref="editHiyaBundleModal"
              id="hiyabundle-update-modal"
              title="Update hiya bundle"
              hide-footer size="m" >
        <b-form @submit="onSubmitUpdate" @reset="onResetUpdate" class="w-200">
            <b-form-row>
          <b-col>
              <b-form-group id="form-Bundlename-group"
                      label="Bundle Name"
                      label-for="form-Bundlename-input" label-cols="4" label-cols-sm="4" label-size="sm">
              <b-form-input id="form-Bundlename-input"
                              type="text"   size="sm"
                              v-model="editHiyaBundleForm.BundleName"
                              required
                              placeholder="Enter name" w-20>
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>

          <b-form-row>
          <b-col>
               <b-form-group id="form-BundleCost-group"
                        label="Bundle Cost"
                        label-for="form-BundleCost-input" label-cols="4" label-cols-lg="4" label-size="sm">
              <b-form-input id="form-BundleCost-input"
                        type="text"   size="sm"
                        v-model="editHiyaBundleForm.BundleCost"
                        required
                        placeholder="Enter bundle cost">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
  
        <b-form-row>
          <b-col>
              <b-form-group id="form-InclusiveQty-group"
                        label="Inclusive Quantity"
                        label-for="form-InclusiveQty-input" label-cols="4" label-cols-lg="4" label-size="sm">
              <b-form-input id="form-InclusiveQty-input"
                        type="text"   size="sm"
                        v-model="editHiyaBundleForm.InclusiveQty"
                        required
                        placeholder="Enter inclusive quantity">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-ExclusiveCharge-group"
                        label="Exclusive Charge"
                        label-for="form-ExclusiveCharge-input" label-cols="4" label-cols-lg="4" label-size="sm">
              <b-form-input id="form-ExclusiveCharge-input"
                        type="text"   size="sm"
                        v-model="editHiyaBundleForm.ExclusiveCharge"
                        required
                        placeholder="Enter exclusive charge">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
         <b-form-row>
            <b-col>
             <b-form-group id="form-Carrier-group"
              label="Carrier"
                        label-for="form-InclusiveText-input" label-cols="4" label-cols-lg="4" label-size="sm" >
              <div >
                  <CarrierDropDownVue v-bind:selected_carrier="editHiyaBundleForm.CarrierId" @carrier-changed="carrierChange"></CarrierDropDownVue>
          </div>
          </b-form-group>
          </b-col>
        </b-form-row>
          <b-form-row v-show="false">
             <b-col>
              <b-form-group id="form-GroupBundle-group"
                        label="Group Bundle"
                        label-for="form-GroupBundle-input" label-cols="4" label-cols-lg="4" label-size="sm" >
              <div class="dropdown" align="left">
                <b-form-checkbox id="GroupBundle"
                    v-model="editHiyaBundleForm.GroupBundle" name="GroupBundle">
              </b-form-checkbox>
              </div>
              </b-form-group>
          </b-col>
          </b-form-row>
          <div class="container">
          <div class="row" v-if="isAdmin">
              <div class="col text-center">
              <b-button-group>
            <b-button type="submit" variant="primary">Submit</b-button>
            <b-button type="reset" variant="danger">Reset</b-button>
          </b-button-group>
              </div>
          </div>
          </div>
        </b-form>
      </b-modal>
    </div>
  </template>


  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import AccountDropdownVue from "../components/AccountDropdown.vue";
  import CarrierDropDownVue from "../components/CarrierDropDown.vue";
  
  import {isAdmin, editButtonText} from '@/auth/AuthService'
  export default {
    Name: "Api",
    data() {
      return {
        isAdmin: isAdmin(),
        editButtonText: editButtonText(),
        SelectedBundleType: '',
        SelectedDirection: '',
        editcomponentKey:0,
        selectedChargeBand:"",
        addHiyaBundleForm: {
          BundleName: '' ,
          BundleType: '' ,
          BundleCost: '' ,
          InclusiveQty: '' ,
          ExclusiveCharge:'',
          CarrierId: '' ,
          GroupBundle: ''
        },
        editHiyaBundleForm: {
          BundleId : '',
          BundleName: '' ,
          BundleCost: '' ,
          InclusiveQty: '' ,
          ExclusiveCharge:'',
          CarrierId: '' ,
          GroupBundle: ''
        },
        apiHiyaBundles: null,
        executed: false,
        message: 'initial alert',
        currentSort:'BundleName',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        filter:'',
        showPaging:true,
      };
    },
  
    computed:{
    filteredHiyaBundles() {
      console.log('filtered hiyabundle');
        return this.apiHiyaBundles.filter(c => {
          if(this.filter == '') return true;
          return c.BundleName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 
        })
      },
    SortedapiHiyaBundles:function() {
      console.log('sort call bundle');
      if (this.apiHiyaBundles != null){
      return this.filteredHiyaBundles.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort].toString().toLowerCase() < b[this.currentSort].toString().toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toString().toLowerCase() > b[this.currentSort].toString().toLowerCase()) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
      }
      else{ return null}
    },
    },
    methods: {
      onChangeDirection(event) {
        this.SelectedDirection= event.target.value
      },
      carrierChange: function (e) {
          this.selectedCarrier = e;
      },
      forceRerender:function() {
        this.editcomponentKey += 1
      },
      hiyabundleChange: function (e) {
          this.selectedAccount = e;
      },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredHiyaBundles.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },
  
      //////////////////////////////////////////////
      // LIST CALL BUNDLE METHODS
      //////////////////////////////////////////////
      async callApi(lablex) {
  
        console.log(lablex)
        console.log('getting the data')
        try {
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/hiyabundles`);
          console.log('got hiya bundle data')
           console.log(data)
          this.apiHiyaBundles = data;
          this.executed = true;
          if (this.apiHiyaBundles.length < this.pageSize){
            this.showPaging = false
          }
        } catch (e) {
          this.apiHiyaBundles = `Error: the server responded with '${e.response}'`;
        }
      },
  
      //////////////////////////////////////////////
      // DELETE CALL BUNDLE METHODS
      //////////////////////////////////////////////
      async removeHiyaBundle(hiyabundleid) {
  
        console.log("in removeHiyaBundle before")
        console.log(hiyabundleid)
        try {
          const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/hiyabundles/${hiyabundleid}`).then(() => {
            this.message = 'hiyabundle removed!';
            this.showMessage = true;
            console.log("in removeHiyaBundle after")
            this.callApi("coming from delete");
  
          }).catch((err) => {
            console.log("in removeHiyaBundle error")
            console.error(err);
            this.callApi();
  
          });
  
          // this.apiHiyaBundles = data;
          // this.executed = true;
          console.log('delete data is:')
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiHiyaBundles = `Error: the server responded with '${e.response}'`;
        }
      },
      onDeleteHiyaBundle(hiyabundle) {
        console.log(hiyabundle.BundleId)
        if(confirm("Are you sure you want to delete the hiya bundle?")){
          this.removeHiyaBundle(hiyabundle.BundleId);
        }
      },
  
  
      //////////////////////////////////////////////
      // ADD HIYA BUNDLE METHODS
      //////////////////////////////////////////////
  
      // Add hiyabundle method to post to API
      async addHiyaBundle(payload) {
  
      //   const id_token = claims.__raw;
        console.log('in add hiyabundle');
        console.log('payload is');
        console.log(payload);
        try {
          const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/hiyabundles`, payload).then(() => {
            this.message = 'hiyabundle added!';
            this.showMessage = true;
            this.callApi();
  
          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated hiyabundle. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add hiyabundle. status code:  " + err.response.status)
              }
            console.error(err);
            this.callApi();
  
          });
  
          // this.apiHiyaBundles = data;
          // this.executed = true;
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiHiyaBundles = `Error: the server responded with '${e.response}'`;
        }
      },
      initForm() {
        console.log('init form');
          this.addHiyaBundleForm.GroupBundle =  false;
          this.addHiyaBundleForm.BundleName =  '';
          this.addHiyaBundleForm.BundleCost =  '';
          this.addHiyaBundleForm.InclusiveQty =  '';
          this.addHiyaBundleForm.ExclusiveCharge =  '';
          this.addHiyaBundleForm.CarrierId =  '';
      },
      // Add call bundle on submit
      onSubmit(evt) {
        console.log('on submit');
        evt.preventDefault();
        this.$refs.addHiyaBundleModal.hide();
        // ***********this need to be looked at again as the check**************
        // box value is not presented when not selected
        if(this.addHiyaBundleForm.BundleType== "") this.addHiyaBundleForm.BundleType= false;
        const payload = {
          BundleName: this.addHiyaBundleForm.BundleName ,
          BundleCost: this.addHiyaBundleForm.BundleCost ,
          InclusiveQty: this.addHiyaBundleForm.InclusiveQty ,
          ExclusiveCharge: this.addHiyaBundleForm.ExclusiveCharge,
          CarrierId: this.selectedCarrier,
          GroupBundle: this.addHiyaBundleForm.GroupBundle
        };
        this.addHiyaBundle(payload);
        this.initForm();
      },
      onReset(evt) {
        evt.preventDefault();
        this.$refs.addHiyaBundleModal.hide();
        this.initForm();
      },
  
      //////////////////////////////////////////////
      // UPDATE HIYA BUNDLE METHODS
      //////////////////////////////////////////////
      editHiyaBundle(hiyabundle) {
        console.log('editHiyaBundle');
        console.log(hiyabundle);
        console.log(hiyabundle.bundle_id);
        this.editHiyaBundleForm = hiyabundle;
        this.callApi()
      },
  
      // Update hiyabundle on submit
      onSubmitUpdate(evt) {
        console.log('in onSubmitUpdate');
        console.log('BundleId');
        evt.preventDefault();
        this.$refs.editHiyaBundleModal.hide();
        // ***********this need to be looked at again as the check**************
        // box value is not presented when not selected
        if(this.editHiyaBundleForm.BundleType== "") this.editHiyaBundleForm.BundleType= false;
        const payload = {
          BundleName: this.editHiyaBundleForm.BundleName ,
          BundleCost: this.editHiyaBundleForm.BundleCost ,
          InclusiveQty: this.editHiyaBundleForm.InclusiveQty ,
          ExclusiveCharge: this.editHiyaBundleForm.ExclusiveCharge,
          CarrierId: this.selectedCarrier,
          GroupBundle: this.editHiyaBundleForm.GroupBundle
        };
        this.updateHiyaBundle(payload, this.editHiyaBundleForm.BundleId);
        this.callApi()
      },
  
      async updateHiyaBundle(payload, hiyabundleid) {
  
        console.log('in update hiyabundle');
        console.log(hiyabundleid);
        try {
          const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/hiyabundles/${hiyabundleid}`, payload).then(() => {
            this.message = 'hiyabundle updated!';
            this.showMessage = true;
            this.callApi();
  
          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated hiyabundle. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add hiyabundle. status code:  " + err.response.status)
              }
  
            console.error(err);
            this.callApi();
  
          });
  
          // this.apiHiyaBundles = data;
          // this.executed = true;
          // console.log('delete data is:')
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiHiyaBundles = `Error: the server responded with '${e.response}'`;
        }
      },
      onResetUpdate(evt) {
        evt.preventDefault();
        this.$refs.editHiyaBundleModal.hide();
        this.initForm();
      },
    },
    created() {
      console.log('created')
      this.callApi();
  
    },
    components: {
      alert: Alert,
      listfilter:Filter,
      AccountDropdownVue: AccountDropdownVue,
      CarrierDropDownVue: CarrierDropDownVue,
    },
  };
  </script>
  <style>
  .table .btn-group {
      white-space: nowrap;
  }
  .table .btn-group .btn {
      display: inline-block;
      float: none;
  }
  .table .btn-group .btn + .btn {
      margin-left: -5px;
  }
  th {
    display: table-cell;
    vertical-align: inherit;
    font-weight: normal;
    text-align: center;
    font-size: 14px;
  }
  td {
    font-size: 12px;
  }
  .btn-custom {
      width: 50px !important;
      height: 17px;
      font-size: 9px;
      font-weight: normal;
      text-align: center;
      background-color: red;
      text-emphasis-color: white;
      color: white;
  }
  
  </style>
  