<template>

    <div>
      <p><u><strong>Account Hiya Bundles List</strong></u></p>
      <br>
      <div class="result-block-container">
        <div :class="['result-block', executed ? 'show' : '']">
          <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.accounthiyabundle-modal>Insert Account Hiya Bundle</button>
          <br><br>
          <input type="search" v-model="filter">
          <table class="table table-striped w-auto" id="apirecords">
            <thead>
              <tr>
                <th class="th-lg" scope="col" @click="sort('account.AccountNumber')" >Account Number</th>
                <th class="th-lg" scope="col" @click="sort('account.Name')" >Account Name</th>
                <th class="th-lg" scope="col" @click="sort('hiyabundle.BundleName')" >Hiya Bundle</th>
                <th scope="col" @click="sort('ModifiedOn')">Date modified</th>
                <th scope="col" @click="sort('ModifiedBy')">Modified by</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(accounthiyabundle, index) in SortedapiAccountHiyaBundle" :key="index">
                <td>{{ accounthiyabundle.account.AccountNumber }}</td>
                <td>{{ accounthiyabundle.account.Name }}</td>
                <td>{{ accounthiyabundle.hiyabundle.BundleName }}</td>
                <td>{{ accounthiyabundle.ModifiedOn }}</td>
                <td>{{ accounthiyabundle.ModifiedBy }}</td>
                <td>
                  <div class="btn-group"  role="group">
                    <p>  </p>
                    <button v-if="isAdmin" v-on:click="onDeleteAccountHiyaBundle(accounthiyabundle)"  type="button"
                            class="btn btn-danger btn-sm">
                        Delete
                    </button>
  
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-show="showPaging"><p align="center" >
            <b-pagination
              v-model="currentPage"
              align="center"
              :total-rows="filteredAccountHiyaBundles.length"
              :per-page="pageSize"
              aria-controls="apirecords"
              ></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
            </p></div>
        </div>
      </div>
  
  
      <!-- ADD modal -->
      <b-modal ref="addAccountHiyaBundleModal"
              id="accounthiyabundle-modal"
              title="Insert a new account hiya bundle"
              hide-footer size="m" >
        <b-form @submit="onSubmit" @reset="onReset" class="w-200">
        <b-form-row>
            <b-col>
             <b-form-group id="form-Account-group"
              label="Account"
                        label-for="form-Account-input" label-cols="4" label-cols-lg="3" label-size="sm" >
              <div >
                  <AccountDropdownVue  @account-changed="accountChange"></AccountDropdownVue>
          </div>
          </b-form-group>
          </b-col>
        </b-form-row>
         <b-form-row>
            <b-col>
             <b-form-group id="form-Bundle-group"
              label="Hiya Bundle"
                        label-for="form-Bundle-input" label-cols="4" label-cols-lg="3" label-size="sm" >
              <div >
                  <HiyaBundleDropDown @hiyabundle-changed="hiyabundleChange"></HiyaBundleDropDown>
          </div>
          </b-form-group>
          </b-col>
        </b-form-row>
  
        <b-form-row>
            <b-col>
         <p></p>
          </b-col>
          </b-form-row>
          <div class="container">
          <div class="row">
              <div class="col text-center">
              <b-button-group>
            <b-button type="submit" variant="primary">Submit</b-button>
            <b-button type="reset" variant="danger">Reset</b-button>
          </b-button-group>
              </div>
          </div>
          </div>
        </b-form>
      </b-modal>
  
    </div>
  </template>
  
  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import AccountDropdownVue from "../components/AccountDropdown.vue";
  import CarrierDropDownVue from "../components/CarrierDropDown.vue";
  import HiyaBundleDropDown from "../components/HiyaBundleDropDown.vue";
  
  import {isAdmin, editButtonText} from '@/auth/AuthService'
  export default {
    Name: "Api",
    data() {
      return {
        isAdmin: isAdmin(),
        editButtonText: editButtonText(),
        editcomponentKey:0,
        selected_bundle:"",
        addAccountHiyaBundleForm: {
          BundleName: '' ,
          BundleCost: '' ,
          InclusiveMinutes: '' ,
          Direction: '' ,
          CarrierId: '' ,
          GroupBundle: ''
        },
  
        apiAccountHiyaBundle: null,
        executed: false,
        message: 'initial alert',
        currentSort:'AHBId',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        filter:'',
        showPaging:true,
      };
    },
  
    computed:{
    filteredAccountHiyaBundles() {
      console.log('filtered accounthiyabundle');
        return this.apiAccountHiyaBundle.filter(c => {
          if(this.filter == '') return true;
          if (c.hiyabundle.BundleName == null) c.hiyabundle.BundleName = '';
          if (c.account.Name == null) c.account.Name = '';
          if (c.account.AccountNumber == null) c.account.AccountNumber = '';
          return c.hiyabundle.BundleName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                 c.account.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                 c.account.AccountNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 
        })
      },
  
    SortedapiAccountHiyaBundle:function() {
      // console.log('sort chargeband');
      // console.log('sort account');
      if (this.apiAccountHiyaBundle == null) return null;
      // console.log ('in sort account....');
      // console.log(this.currentSort)
      return this.filteredAccountHiyaBundles.slice().sort((a,b) => {
        let modifier = 1;
        var subfields = ['account.Name','account.AccountNumber','hiyabundle.BundleName']
        if(this.currentSortDir === 'desc') modifier = -1;
  
        if (subfields.includes(this.currentSort)) {
            var prop = this.currentSort.split('.');
            var len = prop.length;
              var i = 0;
              while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
              if (a < b) {
                  return -1 * modifier;
              } else if (a > b) {
                  return 1 * modifier;
              } else {
                  return 0;
              }
          }
        else{
          if (a[this.currentSort] === null) return 1;
          if (b[this.currentSort] === null) return -1;
          if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
          if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        }
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {
      accountChange: function (e) {
          this.selectedAccount = e;
      },

      hiyabundleChange: function (e) {
          console.log('XXXXXXXXXXXXXXXXXXXXXX in bundlechanged XXXXXXXXXXXXXXXXXXXXXX');
          console.log(e);
          this.selectedHiyaBundle = e;
      },
      forceRerender:function() {
        this.editcomponentKey += 1
      },
      accounthiyabundleChange: function (e) {
          this.selectedAccount = e;
      },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredAccountHiyaBundles.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },
  
      //////////////////////////////////////////////
      // LIST ACCOUNT BUNDLE METHODS
      //////////////////////////////////////////////
      async callApi(lablex) {
  
        console.log(lablex)
        console.log('getting the data')
        try {
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accounthiyabundles`);
          console.log('got account hiya bundle data')
           console.log(data)
          this.apiAccountHiyaBundle = data;
          this.executed = true;
          if (this.apiAccountHiyaBundle.length < this.pageSize){
            this.showPaging = false
          }
        } catch (e) {
          this.apiAccountHiyaBundle = `Error: the server responded with '${e.response}'`;
        }
      },
  
      //////////////////////////////////////////////
      // DELETE CALL BUNDLE METHODS
      //////////////////////////////////////////////
      async removeAccountHiyaBundle(accounthiyabundleid) {
  
        console.log("in removeAccountHiyaBundle before")
        console.log(accounthiyabundleid)
        try {
          const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accounthiyabundles/${accounthiyabundleid}`).then(() => {
            this.message = 'accounthiyabundle removed!';
            this.showMessage = true;
            console.log("in removeAccountHiyaBundle after")
            this.callApi("coming from delete");
  
          }).catch((err) => {
            console.log("in removeAccountHiyaBundle error")
            console.error(err);
            this.callApi();
  
          });
  
          // this.apiAccountHiyaBundle = data;
          // this.executed = true;
          console.log('delete data is:')
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiAccountHiyaBundle = `Error: the server responded with '${e.response}'`;
        }
      },
      onDeleteAccountHiyaBundle(accounthiyabundle) {
        console.log(accounthiyabundle.AHBId)
        if(confirm("Are you sure you want to delete the account hiya bundle?")){
          this.removeAccountHiyaBundle(accounthiyabundle.AHBId);
        }
      },
  
      //////////////////////////////////////////////
      // ADD CALL BUNDLE METHODS
      //////////////////////////////////////////////
  
      // Add accounthiyabundle method to post to API
      async addAccountHiyaBundle(payload) {
  
      //   const id_token = claims.__raw;
        console.log('in add accounthiyabundle');
        console.log('payload is');
        console.log(payload);
        try {
          const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accounthiyabundles`, payload).then(() => {
            this.message = 'accounthiyabundle added!';
            this.showMessage = true;
            this.callApi();
  
          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated accounthiyabundle. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add accounthiyabundle. status code:  " + err.response.status)
              }
            console.error(err);
            this.callApi();
  
          });
  
          // this.apiAccountHiyaBundle = data;
          // this.executed = true;
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiAccountHiyaBundle = `Error: the server responded with '${e.response}'`;
        }
      },
      initForm() {
        console.log('init form');
          this.addAccountHiyaBundleForm.GroupBundle =  false;
      },
      // Add account bundle on submit
      onSubmit(evt) {
        console.log('on submit');
        evt.preventDefault();
        this.$refs.addAccountHiyaBundleModal.hide();
        const payload = {
          AccountId: this.selectedAccount,
          BundleId: this.selectedHiyaBundle
        };
        this.addAccountHiyaBundle(payload);
        this.initForm();
      },
      onReset(evt) {
        evt.preventDefault();
        this.$refs.addAccountHiyaBundleModal.hide();
        this.initForm();
      },
  
  
    },
    created() {
      console.log('created')
      this.callApi();
  
    },
    components: {
      alert: Alert,
      listfilter:Filter,
      AccountDropdownVue: AccountDropdownVue,
      CarrierDropDownVue: CarrierDropDownVue,
      HiyaBundleDropDown: HiyaBundleDropDown,
    },
  };
  </script>
  <style>
  .table .btn-group {
      white-space: nowrap;
  }
  .table .btn-group .btn {
      display: inline-block;
      float: none;
  }
  .table .btn-group .btn + .btn {
      margin-left: -5px;
  }
  th {
    display: table-cell;
    vertical-align: inherit;
    font-weight: normal;
    text-align: center;
    font-size: 14px;
  }
  td {
    font-size: 12px;
  }
  .btn-custom {
      width: 50px !important;
      height: 17px;
      font-size: 9px;
      font-weight: normal;
      text-align: center;
      background-color: red;
      text-emphasis-color: white;
      color: white;
  }
  
  </style>
  