<template>

    <div>
      <p ><u><strong>Usage Summary By Billing Reference Report</strong></u> </p>
      <br>
      <div class="result-block-container">

        <div :class="['result-block', executed ? 'show' : '']">
        <b-form @submit="onSubmit" >
            <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="2"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="4"
            label="Carrier"
            label-for="input-horizontal"
            >
            <div>
                        <CarrierNameDropDownSummaryVue @carrier-changed="carrierChange"></CarrierNameDropDownSummaryVue>
                    </div>
            </b-form-group>

        <!-- <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="2"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="4"
            label="Carrier"
            label-for="input-horizontal"
            >
            <div>
                        <CarrierNameDropDownVue @carrier-changed="carrierChange"></CarrierNameDropDownVue>
                    </div>
            </b-form-group> -->

        <table>
              <tr>
                <td><b-button type="submit" variant="primary">Find</b-button></td>
                <td></td>
                <td><button type="button" class="btn btn-success btn-sm" v-on:click="downloadAll">Email All</button></td>
            </tr>
            </table>
  </b-form>

         <br><br>
         <table v-show="showresult" >
            <tr v-if="apicarrier.length > 0">
                <td><button type="button" class="btn btn-success btn-sm" v-on:click="download">Dowload Results</button></td>
            </tr>
        </table>
        <br><br>
          <table class="table table-striped w-auto" v-show="showresult" >
            <thead>
                <tr>
                    <th scope="col" @click="sort('GroupNumber')">Group Number</th>
                <th scope="col" @click="sort('GroupName')">Group Name</th>
                <th scope="col" @click="sort('AccountNumber')">Account Number</th>
                <th scope="col" @click="sort('AccountName')">Account Name</th>
                <th class="th-lg" scope="col" @click="sort('CostCentreName')" >CostCentreName</th>
                <th class="th-lg" scope="col" @click="sort('BillingReference')" >Billing Reference</th>
                <th class="th-sm" scope="col">Label</th>
                <!-- <th class="th-sm" scope="col">Origin</th> -->
                <th scope="col" @click="sort('CallCount')">Count</th>
                <th scope="col" @click="sort('Duration')">Duration (m)</th>
                <th scope="col" @click="sort('Data_mb')">Data (MB)</th>
                <th scope="col" @click="sort('Quantity')">Quantity</th>
                <th scope="col" @click="sort('Price')">Price (£)</th>
                <th scope="col" @click="sort('Cost')">Cost (£)</th>
                <Div  v-show="showcarrier">
                  <th scope="col" @click="sort('Cost')">Carrier</th>
                  <th></th>
                </Div>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(carrierummary, index) in apicarrier" :key="index">
                <td >{{ carrierummary.GroupNumber }}</td>
                <td >{{ carrierummary.GroupName }}</td>
                <td >{{ carrierummary.AccountNumber }}</td>
                <td >{{ carrierummary.AccountName }}</td>
                <td >{{ carrierummary.CostCentreName }}</td>
                <td >{{ carrierummary.BillingReference }}</td>
                <td >{{ carrierummary.Label }}</td>
                <!-- <td align="center">{{ carrierummary.Origin }}</td> -->
                <td align="center">{{ carrierummary.CallCount }}</td>
                <td align="center">{{ carrierummary.DurationMin }}</td>
                <td align="center">{{ carrierummary.Data_mb }}</td>
                <td align="center">{{ carrierummary.Quantity }}</td>
                <td align="center">{{ carrierummary.Price }}</td>
                <td align="center">{{ carrierummary.Cost }}</td>
                <Div v-show="showcarrier">
                  <td align="center">{{ carrierummary.Carrier }}</td>
                </Div>
                <!-- <td></td> -->
              </tr>
            </tbody>
          </table>
          <!-- <div v-if="apicarrier.length > 0"><p align="center">
            <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button>
            </p></div> -->
        </div>
      </div>

    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import * as XLSX from 'xlsx'
  import CarrierNameDropDownVue from "../components/CarrierNameDropDown.vue";
  import CarrierNameDropDownSummaryVue from "../components/CarrierNameDropDownSummary.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        apicarrier: [],
        executed: false,
        message: 'initial alert',
        currentSort:'AccountName',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        filter:'',
        showresult: false,
        showdownload:false,
        Account: null,
        showcarrier : true
      };
    },

    computed:{
    filteredPricedCalls() {
      console.log('filtered suspendedcall');
        return this.apiPricedCalls
      },
    SortedapiPricedCalls:function() {
      console.log('sort suspendedcall');
      return this.filteredPricedCalls.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {
    onChangeCallType(event) {
            this.SelectedCallType= event.target.value
            },

    onChangeDirection(event) {
        this.SelectedDirection= event.target.value
        },
    accountChange: function (e) {
        this.apiPricedCalls = null;
        this.showresult = false;
        console.log('£££££££££££ in change account £££££££')
        console.log(this.showresult)
        this.selectedAccount = e;
        },


    carrierChange: function (e) {
      this.selectedCarrier = e;
      if (this.selectedCarrier == 'ALL') {
        this.showcarrier = false;
      }
      },
        
    // onChangeReportType(event) {
    //         this.filter = '';
    //         this.SelectedReportType= event.target.value;
    //         },

    onSubmit(evt) {
        console.log('on submit');
        this.apiPricedCalls = null;
        this.showresult = false;
        if (!this.selectedCarrier){ // is null or zero
            alert("Please select an option");
            evt.preventDefault();
                    return false;
        }
        this.callApi(this.selectedCarrier);
        // this.showresult = true;
        evt.preventDefault();

        },


    download : function() {
      console.log('in download results selected carrier')
      console.log(this.selectedCarrier)
      // customised headers
      var Heading = [[]]
      Heading =  [['Group Number','Group Name','Account Number','Account Name', 'Cost Centre Name', 'Billing Reference','Label','Count','Duration (m)', 'Data (MB)', 'Quantity','Price (£)', 'Cost (£)', 'Carrier']
        ];

      // original object headers
      var objHeader = []
      objHeader =['GroupNumber','GroupName','AccountNumber','AccountName', 'CostCentreName', 'BillingReference','Label','CallCount', 'DurationMin', 'Data_mb', 'Quantity', 'Price', 'Cost', 'Carrier'];

      if (this.selectedCarrier == 'ALL') {
        Heading = [
          ['Group Number','Group Name','Account Number','Account Name', 'Cost Centre Name', 'Billing Reference','Label','Count','Duration (m)', 'Data (MB)', 'Quantity', 'Price (£)', 'Cost (£)']
        ];

        // original object headers
        objHeader = ['GroupNumber','GroupName','AccountNumber','AccountName', 'CostCentreName', 'BillingReference','Label','CallCount', 'DurationMin', 'Data_mb', 'Quantity', 'Price', 'Cost'];

      }
      console.log('in download results selected carrier')
      console.log(this.selectedCarrier)
        // build based on original object fields but skip the header row
        const data = XLSX.utils.json_to_sheet(this.apicarrier, { origin: 'A2', header: objHeader, skipHeader: true })
        // add the customised header
        XLSX.utils.sheet_add_aoa(data, Heading, { origin: 'A1' });


        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, data, 'data')
        var moment = require('moment');
        var datestr = moment(new Date()).format('DD-MM-YYYY');
        var filename;
        if (this.selectedCarrier == 'ALL') {
          filename = 'Usage  summary by billing reference - ' + datestr + '.xlsx';
        }
        else {
           filename = 'Usage  summary by billing reference ('+ this.apicarrier[0]['Carrier']  + ') - ' + datestr + '.xlsx';
        }
          
        XLSX.writeFile(wb,filename)
        },

    nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredPricedCalls.length) this.currentPage++;
      },

    prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },

    sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

     // api get download
      async downloadAll() {

        console.log('***********************download*****************************')
        // var url;
        try {
        //   var moment = require('moment');
        //   var datestr = moment(new Date()).format('DD-MM-YYYY');
        //   document.body.style.cursor = "wait";
        //   const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/reports/usagesummarybybillingreference?q=DOWNLOAD`,{ // Note the change here
        //   responseType: 'blob',
        // });
        //   document.body.style.cursor = 'auto';
        //   console.log(' in download all after get')
        //   this.executed = true;
        //   var fileURL = window.URL.createObjectURL(new Blob([data]));
        //   var fileLink = document.createElement('a');
        //   fileLink.href = fileURL;
        //   fileLink.setAttribute('download', "UsageSummaryByBillingReference(All Carriers)-" + datestr +".xlsx");
        //   document.body.appendChild(fileLink);

        //   fileLink.click();
          var url;
          document.body.style.cursor = "wait";
          url = process.env.VUE_APP_API_BASE_URL + '/api/v1/reports/usagesummarybybillingreference?q=DOWNLOAD'  ;
          const { data } = await this.$http.get(url);
          console.log(data);
          document.body.style.cursor = 'auto';

        } catch (e) {
          this.apiInvoices = `Error: the server responded with '${e.response}'`;
        }
      },

      // *******************************************
      // List details
      // *******************************************
    async callApi(selectedcarrier) {

        console.log('getting the data')
        // build the filters for the url
        var url;

        document.body.style.cursor = 'wait';

        try {
          // if (carrier == null){
          url = process.env.VUE_APP_API_BASE_URL + '/api/v1/reports/usagesummarybybillingreference?q=' + selectedcarrier ;
          // }
          // else{
          //   url = process.env.VUE_APP_API_BASE_URL + '/api/v1/reports/carrierdetails/carrier/' + carrier ;
          // }

          console.log(url)
          const { data } = await this.$http.get(url);
          console.log(data);
          this.apicarrier = data;
          this.showresult = true;
          this.showcarrier = true;
          if (this.selectedCarrier == 'ALL') {
            this.showcarrier = false;
          }
          this.executed = true;
        } catch (e) {
          this.apicarrier = `Error: the server responded with '${e.response}'`;
        }
        document.body.style.cursor = 'auto';
      },

    },

    created() {
        console.log('created')
       document.body.style.cursor = 'auto';
      this.executed = true;
    //   this.callApi();

    },
    components: {
      alert: Alert,
      CarrierNameDropDownVue: CarrierNameDropDownVue,
      CarrierNameDropDownSummaryVue:CarrierNameDropDownSummaryVue,
      listfilter:Filter
    },
  };
  </script>
