import Vue from "vue";
import Router from "vue-router";
import Home from "../views/Home.vue";
import Callback from "../views/Callback.vue";
import Profile from "../views/Profile.vue";
import NationalRate from "../views/NationalRate.vue";
import Account from "../views/Account.vue";
import AccountGroup from "../views/AccountGroup.vue";
import NetXGroup from "../views/NetXGroup.vue";
import CDRLoad from "../views/CDRLoad.vue";
import Carrier from "../views/Carrier.vue";
import NetXLoadLog from "../views/NetXLoadLog.vue";
import Outbound from "../views/Outbound.vue";
import CostCentre from "../views/CostCentre.vue";
import Inbound from "../views/Inbound.vue";
import ChargeBand from "../views/ChargeBand.vue"
import ChargeBandCode from "../views/ChargeBandCode.vue"
import Country from "../views/Country.vue"
import CountryCode from "../views/CountryCode.vue"
import Pricing from "../views/Pricing.vue"
import GammaMobile from "../views/GammaMobile.vue"
import MobileBundle from "../views/MobileBundle.vue"
import CallBundle from "../views/CallBundle.vue"
import AccountBundle from "../views/AccountBundle.vue"
import Bill from "../views/Bills.vue"
import Order from "../views/Order.vue"
// import ProductCatalogue from "../views/ProductCatalogue.vue"
import ServiceType from "../views/ServiceType.vue"
import RecurringCharge from "../views/RecurringCharge.vue"
import User from "../views/User.vue"
import AccountChargeBand from "../views/AccountChargeBand.vue"
import AccountCountry from "../views//AccountCountry.vue"
import Invoice from "../views/Invoices.vue"
import PricingSummary from "../views/PriceSummary.vue"
import SMSContract from "../views/SMSContract.vue"
import AccountSMSContract from "../views/AccountSMSContract"
import SuspendedCall from "../views/SuspendedCall"
import PricedCall from "../views/PricedCall"
import RepUsageSummaryByCarrier from "../views/RepUsageSummaryByCarrier.vue"
import RepAccountCostSummary from "../views/RepAccountCostSummary.vue"
import RepUsageSummaryByAccount from "../views/RepUsageSummaryByAccount.vue"
import RepAccountCLI from "../views/RepAccountCLI"
import RepAccountInbound from "../views/RepAccountInbound"
import RepSingleAccounts from "../views/RepSingleAccounts"
import RepCLICallByCarrier from "../views/RepCLICallByCarrier"
import RepInboundCallByCarrier from "../views/RepInboundCallByCarrier.vue"
import RepAccountRecurringCharge from "../views/RepAccountRecurringCharge"
import RepAccountSageCode from "../views/RepAccountSageCode.vue"
// import RepChargeCategory from "../views/RepChargeCategory.vue"
import RepProductCatalogue from "../views/RepProductCatalogue.vue"
import BillRunOrder from "../views/RepBillRunOrder.vue"
import RepBaseRate from "../views/RepBaseRate.vue"
import RepCustomisedRate from "../views/RepCustomisedRate.vue"
import RepServiceType from "../views/RepServiceType.vue"
import RepCarrierCall from "../views/RepCarrierCall.vue"
import RepLessThanBuy from "../views/RepRateLessThanBuy.vue"
import RepNetXBreakdown from "../views/RepNetXBreakdown.vue"
import RepSuspendedCall from "../views/RepSuspendedCall.vue"
import ExportCSV from "../views/ExportCSV.vue"
import History from "../views/History.vue"
import FinalPost from "../views/FinalPost.vue"
import ArchivedCall from "../views/ArchivedCall.vue"
import InvoiceAdd from "../views/InvoiceAdd.vue"
import DirectDebitCollection from "../views/DirectDebitCollection.vue"
import OutboundSearch from "../views/OutboundSearch.vue"
import InboundSearch from "../views/InboundSearch.vue"
import AccountUpload from "../views/AccountUpload.vue"
import IndustryType from "../views/IndustryType.vue"
import CLIUpload from "../views/CLIUpload.vue"
import RecurringChargeUpload from "../views/RecurringChargeUpload.vue"
import InboundUpload from "../views/InboundUpload.vue"
import CostCentreUpload from "../views/CostCentreUpload.vue"
import OutboundHistory from "../views/OutboundHistory.vue"
import RecurringChargeHistory from "../views/RecurringChargeHistory.vue"
import InboundHistory from "../views/InboundHistory.vue"
import AccountHistory from "../views/AccountHistory.vue"
import AccountGroupHistory from "../views/AccountGroupHistory.vue"
import NetXGroupHistory from "../views/NetXGroupHistory.vue"
import RecurringChargeSearch from "../views/RecurringChargeSearch.vue"
import Product from "../views/Product.vue"
import CostCentreHistory from "../views/CostCentreHistory.vue";
import RepGroupAccount from "../views/RepGroupAccount.vue"
import RepNetXGroup from "../views/RepNetXGroup.vue"
import RepRecurringChargeNote from "../views/RepRecurringChargeNote.vue"
import RepAccountClearUp from "../views/RepAccountClearUp.vue"
import RepNetXIncoming from "../views/RepNetXIncoming.vue"
import RepNetXGEOIncomingSummary from "../views/RepNetXGEOIncomingSummary.vue"
import RepNetXGEOIncoming from "../views/RepNetXGEOIncoming.vue"
import RepNetXAccountSetup from "../views/RepNetXAccountSetup.vue"
import RepNetXPricing from "../views/RepNetXPricing.vue"
import RepInboundCallAnalysis from "../views/RepInboundCallAnalysis.vue"
// import RepOutboundCallAnalysis from "../views/RepOutboundCallAnalysis .vue"
import RepCallCostMoreThanBTLs from "../views/RepCallCostMoreThanBTL.vue"
import RepCDialledNumberUsage from "../views/RepDialledNumberUsage.vue"
import RepDuplicatedPricedCall from "../views/RepDuplicatedPricedCalls.vue"
import RepDuplicatedLoadedCall from "../views/RepDuplicatedLoadedCalls.vue"
import InviceDetail from "../views/InvoiceDetail.vue"
import PangeaRate from "../views/PangeaRate.vue"
import GiacomRate from "../views/GiacomRate.vue"
import Category from "../views/Category.vue"
import GammaMobileRate from "../views/GammaMobileRate.vue"
import ProductHistory from "../views/ProductHistory.vue"
import InvoiceArchives from "../views/InvoiceArchives.vue"
import DeletedAccount from "../views/DeletedAccount.vue"
import DeletedAccountGroup from "../views/DeletedAccountGroup.vue"
import DeletedOutbound from "../views/DeletedOutbound.vue"
import DeletedInbound from "../views/DeletedInbound.vue"
import DeletedCostCentre from "../views/DeletedCostCentre.vue"
import DeletedProduct from "../views/DeletedProduct.vue"
import DeletedRecurringCharge from "../views/DeletedRecurringCharge.vue"
import DeletedNetXGroup from "../views/DeletedNetXGroup.vue"
import RepCostCentres from "../views/RepCostCentres.vue"
import RepItemisedUsage from "../views/RepItemisedUsage.vue"
import RepUsageSummaryByBillingRef from "../views/RepUsageSummaryByBillingRef.vue"
import RepUsageSummaryByDestination from "../views/RepUsageSummaryByDestination.vue"
import RepCarriers from "../views/RepCarriers.vue";
import HiyaBundle from "../views/HiyaBundle.vue";
import AccountHiyaBundle from "../views/AccountHiyaBundle.vue"

import { login, isAdmin, isAuthenticated, logout } from "@/auth/AuthService"

Vue.use(Router);
const checkLogin = (to, from, next) => {
  console.log('Check login. Authenticated: ' + isAuthenticated() + ' Admin: ' + isAdmin());
  if ((isAuthenticated()) || to.path === '/callback') {
    return next();
  }
  logout();
  login({ path: '/', query: to.query });
  return next();
};
const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: Home    },
    {
      path: "/callback",
      component: Callback    },
    {
      path: "/profile",
      component: Profile
    },
    {
      path: "/nationalrates",
      component: NationalRate
    },
    {
      path: "/accounts",
      component: Account
    },
    {
      path: "/accountgroups",
      component: AccountGroup
    },
    {
      path: "/accountgroups/",
      component: AccountGroup
    },
    {
      path: "/netxgroups",
      component: NetXGroup
    },
    {
      path: "/cdrloads",
      component: CDRLoad
    },
    {
      path: "/carriers",
      component: Carrier
    },
    {
      path: "/cdrloads/netxloadlogs",
      component: NetXLoadLog
    },
    {
      path: "/outbounds",
      component: Outbound
    },
    {
      path: "/outbounds/",
      component: Outbound
    },
    {
      path: "/costcentres",
      component: CostCentre
    },
    {
      path: "/inbounds",
      component: Inbound
    },
    {
      path: "/chargebands",
      component: ChargeBand
    },
    {
      path: "/chargebandcodes",
      component: ChargeBandCode
    },
    {
      path: "/countries",
      component: Country
    },
    {
      path: "/countrycodes",
      component: CountryCode
    },
    {
      path: "/pricing",
      component: Pricing
    },
    {
      path: "/gammamarkups",
      component: GammaMobile
    }
    ,
    {
      path: "/mobilebundles",
      component: MobileBundle
    }
    ,
    {
      path: "/callbundles",
      component: CallBundle
    }
    ,
    {
      path: "/accountbundles",
      component: AccountBundle
    }
    ,
    {
      path: "/bills",
      component: Bill
    }
    ,
    {
      path: "/orders",
      component: Order
    }
    ,
    {
      path: "/reports/productcatalogue",
      component: RepProductCatalogue
    }
    ,
    {
      path: "/servicetypes",
      component: ServiceType
    }
    ,
    {
      path: "/recurringcharges",
      component: RecurringCharge
    }
    ,
    {
      path: "/users",
      component: User
    }
    ,
    {
      path: "/accountchargebands",
      component: AccountChargeBand
    }
    ,
    {
      path: "/accountcountries",
      component: AccountCountry
    }
    ,
    {
      path: "/invoices",
      component: Invoice
    }
    ,
    {
      path: "/pricing/summary",
      component: PricingSummary
    }
    ,
    {
      path: "/smscontracts",
      component: SMSContract
    }
    ,
    {
      path: "/accountsmscontracts",
      component: AccountSMSContract
    }
    ,
    {
      path: "/pricing/suspendedcalls",
      component: SuspendedCall
    }
    ,
    {
      path: "/pricing/pricedcalls",
      component: PricedCall
    }
    ,
    {
      path: "/reports/usagesummarybycarrier",
      component: RepUsageSummaryByCarrier
    }
    ,
    {
      path: "/reports/accountsummary",
      component: RepAccountCostSummary
    }
    ,
    {
      path: "/reports/usagesummarybyaccount",
      component: RepUsageSummaryByAccount
    }
    ,
    {
      path: "/reports/accountsoutbounds",
      component: RepAccountCLI
    }
    ,
    {
      path: "/reports/accountsinbounds",
      component: RepAccountInbound
    }
    ,
    {
      path: "/reports/singleaccounts",
      component: RepSingleAccounts
    }
    ,
    {
      path: "/reports/clicallsbycarrier",
      component: RepCLICallByCarrier
    }
    ,
    {
      path: "/reports/inboundcallsbycarrier",
      component: RepInboundCallByCarrier
    }
    ,
    {
      path: "/reports/accountsrecurringcharges",
      component: RepAccountRecurringCharge
    }
    ,
    {
      path: "/reports/accountssagecodes",
      component: RepAccountSageCode
    },
    {
      path: "/reports/usagesummarybybillingref",
      component: RepUsageSummaryByBillingRef
    }
    
    // ,
    // {
    //   path: "/reports/chargecategories",
    //   component: RepChargeCategory
    // }
    ,
    {
      path: "/reports/billrunorders",
      component: BillRunOrder
    }
    ,
    {
      path: "/reports/baserates",
      component: RepBaseRate
    }
    ,
    {
      path: "/reports/customisedrates",
      component: RepCustomisedRate
    }
    ,
    {
      path: "/reports/servicetypes",
      component: RepServiceType
    }
    ,
    {
      path: "/reports/carriercalls",
      component: RepCarrierCall
    }
    ,
    {
      path: "/reports/rateslessthanbuy",
      component: RepLessThanBuy
    }
    ,
    {
      path: "/reports/netxbreakdown",
      component: RepNetXBreakdown
    }
    ,
    {
      path: "/reports/suspendedcalls",
      component: RepSuspendedCall
    }
    ,
    {
      path: "/exportcsvs",
      component: ExportCSV
    },
    {
      path: "/histories",
      component: History
    },
    {
      path: "/finalposts",
      component: FinalPost
    },
    {
      path: "/histories/pricedcalls",
      component: ArchivedCall
    }
    ,
    {
      path: "/invoiceadds",
      component: InvoiceAdd
    }
    ,
    {
      path: "/ddcollections",
      component: DirectDebitCollection
    }
    ,
    {
      path: "/outbounds/search",
      component: OutboundSearch
    },
    {
      path: "/inbounds/search",
      component: InboundSearch
    },
    {
      path: "/accounts/load",
      component: AccountUpload
    },
    {
      path: "/industrytypes",
      component: IndustryType
    },
    {
      path: "/outbounds/load",
      component: CLIUpload
    },
    {
      path: "/recurringcharges/load",
      component: RecurringChargeUpload
    },
    {
      path: "/inbounds/load",
      component: InboundUpload
    },
    {
      path: "/costcentres/load",
      component: CostCentreUpload
    },
    {
      path: "/outboundhistory",
      component: OutboundHistory
    },
    {
      path: "/recurringchargehistory",
      component: RecurringChargeHistory
    },
    {
      path: "/inboundhistory",
      component: InboundHistory
    },
    {
      path: "/accounthistory",
      component: AccountHistory
    },
    {
      path: "/accountgrouphistory",
      component: AccountGroupHistory
    },
    {
      path: "/netxgrouphistory",
      component: NetXGroupHistory
    },
    {
      path: "/recurringcharges/search",
      component: RecurringChargeSearch
    },
    {
      path: "/products",
      component: Product
    },
    {
      path: "/costcentrehistory",
      component: CostCentreHistory
    }
    ,
    {
      path: "/reports/groupaccounts",
      component: RepGroupAccount
    }
    ,
    {
      path: "/reports/netxgroups",
      component: RepNetXGroup
    }
    ,
    {
      path: "/reports/recurringchargesnotes",
      component: RepRecurringChargeNote
    }
    ,
    {
      path: "/reports/accountsclearup",
      component: RepAccountClearUp
    }
    ,
    {
      path: "/reports/netxincoming",
      component: RepNetXIncoming
    }
    ,
    {
      path: "/reports/netxgeoincomingsummary",
      component: RepNetXGEOIncomingSummary
    }
    ,
    {
      path: "/reports/netxgeoincoming",
      component: RepNetXGEOIncoming
    }
    ,
    {
      path: "/reports/netxaccountsetup",
      component: RepNetXAccountSetup
    }
    ,
    {
      path: "/reports/netxpricing",
      component: RepNetXPricing
    }
    ,
    {
      path: "/reports/inboundcallanalysis",
      component: RepInboundCallAnalysis
    }
    ,
    {
      path: "/reports/usagesummarybydestination",
      component: RepUsageSummaryByDestination
    },
    {
      path: "/reports/callcostmorethanbtl",
      component: RepCallCostMoreThanBTLs
    }
    ,
    {
      path: "/reports/diallednumberusage",
      component: RepCDialledNumberUsage
    }
    ,
    {
      path: "/reports/duplicatedpricedcalls",
      component: RepDuplicatedPricedCall
    }
    ,
    {
      path: "/reports/duplicatedloadedcalls",
      component: RepDuplicatedLoadedCall
    },
    {
      path: "/invoiceemail",
      component: InviceDetail
    },
    {
      path: "/pangeamarkup",
      component: PangeaRate
    },
    {
      path: "/giacommarkup",
      component: GiacomRate
    },
    {
      path: "/categories",
      component: Category
    }
    ,
    {
      path: "/gammamobilemarkup",
      component: GammaMobileRate
    },
    {
      path: "/producthistory",
      component: ProductHistory
    },
    {
      path: "/invoices/archivedinvoices",
      component: InvoiceArchives
    },
    {
      path: "/deletedaccounts",
      component: DeletedAccount
    },
    {
      path: "/deletedaccountgroups",
      component: DeletedAccountGroup
    },
    {
      path: "/outbounds/deletedoutbounds",
      component: DeletedOutbound
    },
    {
      path: "/inbounds/deletedinbounds",
      component: DeletedInbound
    },
    {
      path: "/deletedcostcentres",
      component: DeletedCostCentre
    },
    {
      path: "/deletedproducts",
      component: DeletedProduct
    },
    {
      path: "/recurringcharges/deletedrecurringcharges",
      component: DeletedRecurringCharge
    },
    {
      path: "/deletednetxgroups",
      component: DeletedNetXGroup
    },
    {
      path: "/reports/costcentres",
      component: RepCostCentres
    },
    {
      path: "/reports/itemisedusage",
      component: RepItemisedUsage
    },
    {
      path: "/reports/carriers",
      component: RepCarriers
    },
    {
      path: "/hiyabundles",
      component: HiyaBundle
    },
    {
      path: "/accounthiyabundles",
      component: AccountHiyaBundle
    }
    
  ]
});
router.beforeEach(checkLogin);
export default router;
