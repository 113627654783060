<template>
    <div class="cascading-dropdown">
      <div class="dropdown">
        <select @change="onChangeHiyaBundle($event)" v-model="selected_bundle">
          <option value="">Select a hiya bundle</option>
          <option
            v-for="(hiyabundle, index) in apiHiyaBundles"
            :value="hiyabundle.BundleId"
            :key="index"
          >
            {{ hiyabundle.BundleName }}
          </option>
        </select>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "App",
    props:{
      group_exclude:{
        type: Boolean,
        default: false},
        selected_bundle:{
        type: String,
        default: ""},
      },
    data() {
      return {
        listHiyaBundle: [],
        selectedHiyaBundle: "",
        authToken: "",
        currentSort:'BundleName',
        currentSortDir:'asc',
        apiHiyaBundles: null,
      };
    },
    methods: {
      onChangeHiyaBundle(event) {
        this.selectedHiyaBundle = event.target.value
        console.log('in dropdownXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX')
        console.log(this.selectedHiyaBundle)
        this.$emit('hiyabundle-changed',this.selectedHiyaBundle)
      },
  
      async callApi() {
        try {
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/hiyabundles`);
  
          console.log(data)
          // this.listHiyaBundle = data;
          this.apiHiyaBundles = data;
          this.executed = true;
        } catch (e) {
          this.listHiyaBundle = `Error: the server responded with '${e.response}'`;
        }
      },
    },
    created() {
      this.callApi();
    }
  };
  </script>
  